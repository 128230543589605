// import qs from 'qs'    
import { get } from "./request";

// 车辆-工单  评估报告
export const getEvaluateReport=(param)=>{
  let url = 'erp-manage-vehicle';   //  默认车辆
  if(param.type == 'ticket'){
    url = 'erp-manage-ticket'
  }
  return get(`/saas-api/${url}/evaluateDetect/getReport/${param.vehicleId}`,param)
}
// 报价单
export const quotationDetail=(param)=>get(`/saas-api/erp-manage-ticket/v1/tickets/${param.id}/quotationDetail`,param)
// 报价单--估值建议价
export const getPrice =(param)=>get(process.env.VUE_APP_CHEJIAN_API+`/CarPrice/GetPrice`,param)
// 车辆配置
export const getVehicleModelConfig = (param) => get(`/saas-api/erp-manage-vehicle/modelConfig/getVehicleModelConfig`,param)
// 报价单
export const getVehicleQuotationPrices = (param) => get(`/saas-api/erp-manage-vehicle/vehicleQuotationPrices/${param.id}`,param)
// 分享排名列表
export const getsharingRankings = (param) => get(`/saas-api/erp-manage-bi/v1/shares/h5/sharingRankings`,param)
// 获取微信分享口令
export const getWeixinTicket = (param) => get(`/saas-api/erp-ecommerce/wechatMP/getJsapiTicket`,param)

