import axios from 'axios'; // 引入axios

// 设置请求超时
// 通过axios.defaults.timeout设置默认的请求超时时间。例如超过了10s，就会告知用户当前请求超时，请刷新等。
axios.defaults.timeout = 10000;

const service = axios.create({
  timeout: 15000, // 请求超时时间
})

// 是否下载
// let isDownFile = false;
// 请求拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(response => {
  if (response.data.code == "00000" || response.data.code == "200" || response.config.url.match('chejia3.carwins')) {
    return response
  } else {
    return Promise.reject(response)
  }
}, error => {
  return Promise.reject(error)
})


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, headers) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params,
      headers: headers,
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function patch(url, params, headers) {
  return new Promise((resolve, reject) => {
    service.patch(url, {
      params: params,
      headers: headers,
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, headers) {
  return new Promise((resolve, reject) => {
    service.post(url, params, headers)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params, headers) {
  return new Promise((resolve, reject) => {
    service.put(url, params, headers)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/** 
 * delete方法，对应del请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function del(url, params, headers) {
  return new Promise((resolve, reject) => {
    let formData = new FormData()
    for (let i in params) {
      formData.append(i, params[i])
    }
    service.delete(url, { data: formData }, params, headers)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}